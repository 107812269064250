export const surveyQuestions = {
  followUp1: {
    questions: {
      aiPotentialForPub: ["be published in a top journal in your field?"],
      aiAdvanceInField: ["advance science in your field?"],
      aiAdvanceOtherField: ["advance science in adjacent fields?"],
      aiBenefitSoc: ["benefit society?"],
    },
    header:
      "Say the AI suggestion text were developed into completed research. How likely is it that the research based on the AI suggestion text would...",
  },
  followUp2: {
    questions: {
      aiAssistSpecificFuture: [
        "The AI suggestion text assisted me with the specific future research idea that I described?",
      ],
      aiAssistGeneralThoughts: [
        "The AI suggestion text assisted me with general thoughts about my research agenda?",
      ],
      excitedPotentialOfIdea: [
        "How excited are you about the potential of your submitted research idea?",
      ],
      selfCredit: [
        "How likely are you to credit yourself with for the future research idea that you submitted?",
      ],
      ideaPotentialForPub: ["be published in a top journal in your field?"],
      ideaAdvanceInField: ["advance science in your field?"],
      ideaAdvanceOtherField: ["advance science in adjacent fields?"],
      ideaBenefitSoc: ["benefit society?"],
    },
    header:
      "Thinking about the AI suggestion text, please indicate the extent to which you agree with the following statements:",
  },
  followUp3: {
    questions: {
      enjoyedThinking: [
        "I enjoyed thinking about new research ideas in this exercise.",
      ],
      excitedByFutureDirections: [
        "I am excited by the future directions my research might take.",
      ],
      believeAdvanceScience: [
        "I believe my research will help advance science.",
      ],
      believePositiveImpactSoc: [
        "I believe my research will have a positive impact on society.",
      ],
      feelFatigued: ["I feel fatigued when I think about my research agenda."],
      excerciseChangedThoughts: [
        "This exercise changed how I think of my future research agenda.",
      ],
    },
    header:
      "Please indicate the extent to which you agree with the following statements: 1=Not at all, 9=Extremely:",
  },
  followUp4: {
    questions: {
      novel: ["How novel do you think your future research idea is?"],
      original: ["How original do you think your future research idea is?"],
      rare: [
        "How rare (e.g. unusual) do you think your future research idea is?",
      ],
    },
    header:
      "Please indicate the extent to which you agree with the following statements: 1=Not at all, 9=Extremely:",
  },
  followUp5: {
    questions: {
      appropriateForTargetJournals: [
        "How appropriate do you think your future research idea is for the target journals?",
      ],
      feasibleIdea: ["How feasible do you think your future research idea is?"],
      likelihoodForIdeaToBePublished: [
        "How likely do you think your future research idea is to be published?",
      ],
    },
    header:
      "Please indicate the extent to which you agree with the following statements: 1=Not at all, 9=Extremely:",
  },
  followUp7: {
    questions: {
      comfortableNewTech: ["How comfortable are you with new technologies?"],
      previouslyUsedAi: [
        "How often (if at all) have you previously engaged with generative AI or similar technologies?",
      ],
      aiGeneratingIdeasUnethical: [
        "Relying on generative AI tools to assist with generating ideas for research is unethical",
      ],
      aiAssistWritingUnethical: [
        "Relying on generative AI to assist with the writing of academic research papers is unethical",
      ],
      aiUseShouldBeDisclosed: [
        "If a researcher uses generative AI as part of the research process, the researcher should disclose how generative AI was used",
      ],
      aiToolAddedCoauthor: [
        "There are circumstances in which the contributions to a research paper by generative AI warrant adding the tool as a co-author",
      ],
      aiAdvanceScienceOtherFields: [
        "Its potential to advance science in other adjacent fields",
      ],
      aiWillLowerQuality: [
        "Research that uses generative AI will be of lower quality, on average",
      ],
    },
  },
};
