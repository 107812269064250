import React from "react";
import BaseFollowUpPage from "../components/BaseFollowUpPage.jsx";
import { surveyQuestions } from "../config/questions.js";

export default function FollowUp2(props) {
  return (
    <BaseFollowUpPage
      {...props}
      showAiSuggestions={true}
      header={surveyQuestions.followUp2.header}
      questionSets={surveyQuestions.followUp2.questions}
    />
  );
}
