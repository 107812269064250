import React from "react";
import BaseFollowUpPage from "../components/BaseFollowUpPage.jsx";
import { surveyQuestions } from "../config/questions.js";

export default function FollowUp3(props) {
  return (
    <BaseFollowUpPage
      {...props}
      header={surveyQuestions.followUp3.header}
      questionSets={surveyQuestions.followUp3.questions}
    />
  );
}
