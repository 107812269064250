import React from "react";
import { Button } from "../components/Button.jsx";
import consentText from "../resources/consentText.json";
import { BrowserView, MobileView } from "react-device-detect";

export function Consent({ onConsent }) {
  return (
    <div>
      <BrowserView>
        <div className="sm:mt-5 p-20">
          <div className="mb-6">
            <h2 className="title">{consentText.consentTitle}</h2>
            <p>{consentText.consentIntroduction}</p>

            {consentText.consentSections.map((section, index) => (
              <React.Fragment key={index}>
                <h2 className="title">{section.title}</h2>
                <p>{section.content}</p>
              </React.Fragment>
            ))}

            <ul className="list-inside list-disc mb-6">
              {consentText.bulletPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>

            <div className="flex-c">
              <Button className="consent-btn" autofocus handleClick={onConsent}>
                <p>I have read and consent to the above.</p>
              </Button>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <UnsupportedBrowser />
      </MobileView>
    </div>
  );
}

function UnsupportedBrowser() {
  return (
    <div className="container flex justify-center items-center h-screen">
      Please use a computer to complete this survey.
    </div>
  );
}
