import { useRound } from "@empirica/core/player/classic/react";
import React from "react";
import { PaperSubmit } from "./tasks/PaperSubmit.jsx";
import { Ideas } from "./tasks/Ideas.jsx";
import FollowUp1 from "./post-survey/pages/FollowUp1.jsx";
import FollowUp2 from "./post-survey/pages/FollowUp2.jsx";
import FollowUp3 from "./post-survey/pages/FollowUp3.jsx";
import FollowUp4 from "./post-survey/pages/FollowUp4.jsx";
import FollowUp5 from "./post-survey/pages/FollowUp5.jsx";
import FollowUp6 from "./post-survey/pages/FollowUp6.jsx";
import FollowUp7 from "./post-survey/pages/FollowUp7.jsx";
import PrizeEntry from "./post-survey/pages/PrizeEntry.jsx";

export function Stage() {
  const round = useRound();

  switch (round.get("task")) {
    case "paper_submit":
      return <PaperSubmit />;
    case "idea_submit":
      return <Ideas />;
    case "follow_up_1":
      return <FollowUp1 />;
    case "follow_up_2":
      return <FollowUp2 />;
    case "follow_up_3":
      return <FollowUp3 />;
    case "follow_up_4":
      return <FollowUp4 />;
    case "follow_up_5":
      return <FollowUp5 />;
    case "follow_up_6":
      return <FollowUp6 />;
    case "follow_up_7":
      return <FollowUp7 />;
    case "prize":
      return <PrizeEntry />;
    default:
      return <div>Unknown task</div>;
  }
}
