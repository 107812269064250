import React, { useState, useEffect } from "react";
import AiContainer from "../components/AiContainer.jsx";
import { useGame, usePlayer } from "@empirica/core/player/classic/react";
import { Button } from "../components/Button.jsx";
export function Ideas() {
  const game = useGame();
  const player = usePlayer();
  const [idea, setIdea] = useState("");
  const [showSubmitError, setShowSubmitError] = useState(false);
  const wordLimit = 200;

  const handleUpdate = (event) => {
    const { value } = event.currentTarget;
    setIdea(value);
  };

  const handleSubmit = () => {
    const words = idea.split(" ").filter(Boolean).length;
    if (words > 200) {
      setShowSubmitError(true);
      return;
    }
    setShowSubmitError(false);
    player.set("idea", idea);
    player.stage.set("submit", true);
  };

  const treatment = game.get("treatment");

  const isDisabled = idea.split(" ").filter(Boolean).length < 75;

  return (
    <div className="container">
      <h1 className="title leading-6 text-gray-900">Part 2</h1>
      <p>
        <strong>Instructions:</strong> Based on the title/abstract you provided
        (reproduced here at the end of this page), please propose a potential
        future research idea that is related to your published work. This could
        be areas that you believe deserve further investigation or topics that
        could build upon your findings. Please ensure your submission is between
        75 and 200 words.
      </p>

      {treatment.condition !== 0 && <AiContainer player={player} game={game} />}

      <div className="taskResponse-container">
        <h2>Idea:</h2>

        <div className="input-container">
          <textarea
            className="task-input"
            name="idea"
            id="idea"
            value={idea}
            onChange={handleUpdate}
          />
          <p>
            <em>
              {idea.split(" ").filter(Boolean).length}/{wordLimit} words
            </em>
          </p>
          <p>(75 word minimum, 200 word maximum)</p>
        </div>
      </div>
      <Button
        className="main-btn"
        handleClick={handleSubmit}
        disabled={isDisabled}
      >
        Submit
      </Button>
      <br />
      {showSubmitError && (
        <>
          <br />
          <p className="prompt-alert">
            Word count exceeds maximum of 200 words.
          </p>
        </>
      )}
      <br />
      <div>
        <p>
          <strong>We reproduce your paper's title and abstract below:</strong>
        </p>

        <p>
          <strong>Title: </strong>
          {player.get("paperTitle")}
        </p>
        <p>
          <strong>Abstract: </strong>
          {player.get("paperAbstract")}
        </p>
      </div>
    </div>
  );
}
