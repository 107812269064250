import React, { useState } from "react";
import SubjectAreas from "../resources/demographics.json";
import { Button } from "../components/Button.jsx";

export function Demographics({ next }) {
  const [demographics, setDemographics] = useState({
    subject: "",
    subjectArea: "",
    researchExperience: "",
    jobTitle: "",
    gender: "",
    genderOther: "",
    age: "",
  });

  const generateOptionsRange = (start, end) => {
    let arr = [];

    for (let i = start; i <= end; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  };

  let allAnswered = false;
  if (
    demographics.gender !== "" &&
    demographics.age > 0 &&
    demographics.jobTitle != "" &&
    demographics.subjectArea != "" &&
    demographics.subject != "" &&
    demographics.researchExperience != ""
  ) {
    allAnswered = true;
  }

  return (
    <div className="py-8 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
      <h2 className="title leading-6 text-gray-900">Demographics</h2>
      <form className="space-y-2">
        <p>What subject area do you do research in? </p>
        <select
          name="subjectArea"
          value={demographics.subjectArea}
          onChange={(e) =>
            setDemographics({
              ...demographics,
              subjectArea: e.currentTarget.value,
            })
          }
          className="main-select"
        >
          <option value="">Select an option</option>
          {Object.keys(SubjectAreas).map((_SA, index) => (
            <option key={index} value={_SA}>
              {_SA}
            </option>
          ))}
        </select>

        {demographics.subjectArea !== undefined &&
          demographics.subjectArea !== "" && (
            <div className="py-2">
              <p>What specific subject do you research?</p>
              <select
                name="subject"
                value={demographics.subject}
                onChange={(e) =>
                  setDemographics({
                    ...demographics,
                    subject: e.currentTarget.value,
                  })
                }
                className="main-select"
              >
                <option value="">Select an option</option>

                {SubjectAreas[demographics.subjectArea].map(
                  (_subject, index) => (
                    <option key={index} value={_subject}>
                      {_subject}
                    </option>
                  )
                )}
              </select>
            </div>
          )}

        <div className="py-2">
          <p>How many years of research experience do you have?</p>
          <select
            name="researchExperience"
            onChange={(e) =>
              setDemographics({
                ...demographics,
                researchExperience: e.currentTarget.value,
              })
            }
            value={demographics.researchExperience}
          >
            <option value="">Select an option</option>
            <option value="<1 Year">{"<1 Year"}</option>
            {generateOptionsRange(1, 50)}
            <option value=">50 Year">{">50 Years"}</option>
          </select>
        </div>

        <div className="py-2">
          <p>Current job title:</p>
          <input
            type="text"
            name="jobTitle"
            value={demographics.jobTitle}
            onChange={(e) =>
              setDemographics({
                ...demographics,
                jobTitle: e.currentTarget.value,
              })
            }
          />
        </div>

        <div className="py-2">
          <p>What gender do you identify with?</p>
          <select
            name="gender"
            onChange={(e) =>
              setDemographics({
                ...demographics,
                gender: e.currentTarget.value,
              })
            }
            value={demographics.gender}
          >
            <option value="">Select an option</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </select>
        </div>

        {demographics.gender === "Other" && (
          <div className="py-2">
            <p>Please enter the gender that you identify with:</p>
            <input
              type="text"
              name="genderOther"
              value={demographics.genderOther}
              onChange={(e) =>
                setDemographics({
                  ...demographics,
                  genderOther: e.currentTarget.value,
                })
              }
            />
          </div>
        )}

        <div className="py-2">
          <p>What is your current age?</p>
          <select
            name="age"
            onChange={(e) =>
              setDemographics({
                ...demographics,
                age: e.currentTarget.value,
              })
            }
            value={demographics.age}
          >
            <option value="">Select an option</option>
            {generateOptionsRange(21, 99)}
          </select>
        </div>
      </form>

      {!allAnswered && (
        <p className="prompt-alert space-y-2">
          Please answer all the questions to continue
        </p>
      )}

      {allAnswered && (
        <div className="flex-c py-2">
          <Button
            className="main-btn"
            handleClick={next}
            disabled={!allAnswered}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}
