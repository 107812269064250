import React from "react";
import { usePlayer } from "@empirica/core/player/classic/react";

export function MatrixQ({
  dbIndex,
  head,
  questions,
  responseScale,
  showHeader,
}) {
  const player = usePlayer();
  const handleChange = (e) => {
    let radio = e.currentTarget;
    let answers = player.get(dbIndex) ?? {};

    if (radio.checked) {
      answers[radio.name] = radio.value;
      player.set(dbIndex, answers);
    }
  };

  let answers = player.get(dbIndex) ?? {};

  return (
    <div>
      {head && (
        <>
          <p>
            <strong>{head}</strong>
          </p>
          <br />
        </>
      )}

      <div className="matrix">
        <table>
          {showHeader && (
            <thead>
              <tr>
                <th></th>
                {responseScale.map((response, index) => {
                  if (response[1] === " ") {
                    return (
                      <th key={index}>
                        <p>{response[0]}</p>
                        <p>{response.slice(1, response.length)}</p>
                      </th>
                    );
                  }
                  return (
                    <th key={index}>
                      <p>{response}</p>
                      <br />
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {questions.map((question, questionIndex) => {
              return (
                <tr key={questionIndex}>
                  <td></td>
                  {responseScale.map((response, responseIndex) => {
                    return (
                      <td className="matrix-input" key={responseIndex}>
                        <input
                          type="radio"
                          name={question}
                          value={response}
                          checked={answers[question] === response}
                          onChange={handleChange}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
