import React, { useState } from "react";
import { usePlayer, useGame } from "@empirica/core/player/classic/react";
import { Button } from "../components/Button";

export function PaperSubmit() {
  const [paperTitle, setPaperTitle] = useState("");
  const [paperAbstract, setPaperAbstract] = useState("");
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [showReview, setShowReview] = useState(false);
  // const [seenReview, setSeenReview] = useState(false);

  const player = usePlayer();
  const game = useGame();

  const handleChange = (event) => {
    // setShowReview(paperAbstract.split(" ").filter(Boolean).length < 100);
    setPaperAbstract(event.currentTarget.value);
  };

  const handleSubmit = (event) => {
    if (paperAbstract.trim().length === 0 || paperTitle.trim().length === 0) {
      setShowSubmitError(true);
      return;
    }

    setShowSubmitError(false);

    if (paperAbstract.split(" ").filter(Boolean).length < 100) {
      setShowReview(true);
      return;
    }

    player.set("paperTitle", paperTitle);
    player.set("paperAbstract", paperAbstract);
    player.set("condition", game.get("treatment").condition);
    player.stage.set("submit", true);
  };

  return (
    <div className="container">
      <h1 className="title leading-6 text-gray-900">Part 1</h1>
      <p>
        <strong>Instructions:</strong> Please copy and paste the title and
        abstract (at least 100 words) of one your most recent published academic
        papers <strong>directly</strong> into the text boxes below. If you do
        not have any recent publications, please select a current unpublished
        working paper. Ideally, the abstract is for a paper that you are hoping
        to conduct follow up research on.
      </p>
      <p>
        <em>Do not worry about any formatting issues</em>
      </p>
      <div className="taskResponse-container">
        <p>
          <strong>Paper title:</strong>
        </p>

        <div className="input-container">
          <textarea
            className="title-input"
            name="paperTitle"
            id="paperTitle"
            onChange={(e) => setPaperTitle(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className="taskResponse-container">
        <p>
          <strong>Abstract:</strong>
        </p>

        <div className="input-container">
          <textarea
            className="abstract-input"
            name="paperAbstract"
            id="paperAbstract"
            onChange={handleChange}
          />
        </div>
      </div>

      {showReview && (
        <p className="prompt-help">
          Please review the text of the title and abstract that you submitted
          and ensure that the text is provided in the two text boxes below.
          <br />
          Please make sure the provided abstract is at least 100 words.
        </p>
      )}

      {showSubmitError && (
        <p className="prompt-alert">
          Please submit both your paper title and abstract.
        </p>
      )}
      <Button className="main-btn" handleClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
}
