import React, { useState } from "react";
import { usePlayer } from "@empirica/core/player/classic/react";
import { Button } from "../components/Button.jsx";

export function ExitSurvey({ next }) {
  const [comment, setComment] = useState("");

  const player = usePlayer();

  function handleSubmit(event) {
    event.preventDefault();
    player.set("exitSurvey", {
      comment,
    });
    if (!player.get("finishedStudy")) {
      player.set("finishedStudy", true);
      player.set("finishStudyAt", Date().toString());
    }
    next();
  }

  return (
    <div className="py-8 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="title leading-6 text-gray-900">Exit Survey</h2>
      <form onSubmit={handleSubmit}>
        <p>
          If you have comments about the study, or a problem occured during the
          study, please write them here:
        </p>
        <textarea
          name="comment"
          autoComplete="off"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="main-input"
          style={{ width: "500px", height: "100px" }}
        />
        <br />
        <br />

        <div className="flex-c">
          <Button className="main-btn mb-12" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
