import React from "react";
import { MatrixQ } from "../../components/MatrixQ.jsx";
import { Scale9 } from "../../components/Scales.jsx";
import { Button } from "../../components/Button.jsx";
import { usePlayer, useGame } from "@empirica/core/player/classic/react";

export default function BaseFollowUpPage({
  questionSets,
  showIdea = true,
  showAiSuggestions = false,
  header,
  instructions,
}) {
  const player = usePlayer();
  const game = useGame();
  const { condition } = game.get("treatment");

  //TODO fix to disable when any answer is empty
  const disabledCondition = Object.entries(questionSets)
    .map(([name, questions]) => player.get(name))
    .some((answers) =>
      Object.values(answers ?? {}).some((answer) => answer === "")
    );

  const handleSubmit = () => {
    player.stage.set("submit", true);
  };

  return (
    <div className="container">
      <div>
        <h2 className="flex-c title leading-6 text-gray-900">
          Additional Questions
        </h2>
      </div>
      {showAiSuggestions && condition !== 1 && (
        <>
          <p>
            <strong>
              Here {condition === 3 ? "are" : "is"} the AI suggestion
              {condition === 3 && "s"} text you received:
            </strong>
          </p>
          <div className="prompt-response">
            <p>
              AI Suggestion{condition === 3 && " 1"}:{" "}
              {player.get("aiResponse1")}
            </p>
          </div>
          {condition === 3 && (
            <>
              <div className="prompt-response">
                <p>AI Suggestion 2: {player.get("aiResponse2")}</p>
              </div>
              <div className="prompt-response">
                <p>AI Suggestion 3: {player.get("aiResponse3")}</p>
              </div>
            </>
          )}
          <br />
        </>
      )}

      {showIdea && (
        <>
          <p>
            <strong>Here is the future research idea you submitted:</strong>
          </p>
          <div className="prompt-response">
            <p>{player.get("idea")}</p>
          </div>
        </>
      )}

      {header && <h3 className="mt-8 font-bold">{header}</h3>}
      {instructions && <p>{instructions}</p>}

      {Object.entries(questionSets).map(([name, question]) => (
        <React.Fragment key={name}>
          <p className="mt-8">
            <strong>
              <em>{question}</em>
            </strong>
          </p>
          <MatrixQ
            player={player}
            dbIndex={name}
            questions={[question]}
            responseScale={Scale9}
            head=""
            showHeader={true}
          />
          <br />
        </React.Fragment>
      ))}

      <Button
        className="main-btn flex-c"
        handleClick={handleSubmit}
        disabled={disabledCondition}
      >
        Next
      </Button>
    </div>
  );
}
