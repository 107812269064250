import React from "react";
import BaseFollowUpPage from "../components/BaseFollowUpPage.jsx";
import { surveyQuestions } from "../config/questions.js";

export default function FollowUp7(props) {
  return (
    <div>
      <BaseFollowUpPage
        {...props}
        header={surveyQuestions.followUp7.header}
        questionSets={surveyQuestions.followUp7.questions}
        showIdea={false}
      />
    </div>
  );
}
