import React, { useState } from "react";
import { Button } from "../../components/Button.jsx";
import { usePlayer } from "@empirica/core/player/classic/react";

export default function FollowUp6() {
  const player = usePlayer();

  const [whoProvidedSparkForIdea, setWhoProvidedSparkForIdea] = useState("");

  const handleUpdate = (event) => {
    const { value, name } = event.currentTarget;
    setWhoProvidedSparkForIdea(value);
    player.set(`${name}`, value);
  };

  const disabledCondition = whoProvidedSparkForIdea.length < 1;

  return (
    <div className="container">
      <p>
        <strong>Here is the future research idea you submitted:</strong>
      </p>
      <div className="prompt-response">
        <p>{player.get("idea")}</p>
      </div>

      <h3>
        In your own words, who do you identify as having provided the original
        spark for the future research idea you described?
      </h3>
      <div className="input-container">
        <textarea
          className="task-input"
          name="whoProvidedSparkForIdea"
          id="whoProvidedSparkForIdea"
          value={whoProvidedSparkForIdea}
          onChange={handleUpdate}
        />
      </div>

      <Button
        className="main-btn flex-c"
        handleClick={() => player.stage.set("submit", true)}
        disabled={disabledCondition}
      >
        Next
      </Button>
    </div>
  );
}
