import React, { useState } from "react";
import { Button } from "../../components/Button.jsx";
import { usePlayer } from "@empirica/core/player/classic/react";

export default function PrizeEntry() {
  const player = usePlayer();
  const [email, setEmail] = useState("");

  const handleUpdate = (event) => {
    const { value, name } = event.currentTarget;
    setEmail(value);
  };

  //TODO store email in global state (Game?)
  const handleSubmit = () => {
    if (email.length > 0) {
      player.set("email", email);
    }

    player.stage.set("submit", true);
  };

  return (
    <div className="container">
      <h2 className="title leading-6 text-gray-900 mb-4">
        Optional Prize Drawing
      </h2>
      <p>
        Thank you for taking the time to complete this survey. Your responses
        have been recorded.
      </p>
      <p>
        If you would like to be considered for the prize drawing, please enter
        your email address below. It will not be stored with your responses.
      </p>
      <p className="mb-4">
        If you do not want to be considered for the drawing, leave the field
        blank.
      </p>

      <div className="input-email">
        <input
          type="text"
          className="email-input"
          name="email"
          id="email"
          value={email}
          onChange={handleUpdate}
        />
      </div>
      <br />

      <p>
        <strong>Please press Submit to complete the survey.</strong>
      </p>

      <Button className="main-btn" handleClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
}
