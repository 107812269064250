export const DisagreeAgree5 = [
  "Strongly Disagree",
  "Somewhat Disagree",
  "Neither Disagree or Agree",
  "Somewhat Agree",
  "Strongly Agree",
]

export const NotWellExtremelyWell5 = [
  "Not well at all",
  "Slightly well",
  "Moderately well",
  "Very well",
  "Extremely well",
]

export const Extent5 = [
  "Not at all",
  "A little",
  "Moderately",
  "Quite a bit",
  "Extremely",
]

export const Scale9 = [
  "1 (Not at all)",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9 (Extremely)",
]
