import React from "react";
import BaseFollowUpPage from "../components/BaseFollowUpPage.jsx";
import { surveyQuestions } from "../config/questions.js";

export default function FollowUp5(props) {
  return (
    <div>
      <BaseFollowUpPage
        {...props}
        header={surveyQuestions.followUp5.header}
        questionSets={surveyQuestions.followUp5.questions}
        showIdea={true}
      />
    </div>
  );
}
