import React, { useState, useEffect } from "react";

export default function AiContainer({ player, game }) {
  const { condition } = game.get("treatment");

  const [aiResponse1, setAiResponse1] = useState(undefined);
  const [aiResponse2, setAiResponse2] = useState(undefined);
  const [aiResponse3, setAiResponse3] = useState(undefined);

  const isLoading = () => {
    return (
      player.get("aiResponse1") === undefined ||
      player.get("aiResponse2") === undefined ||
      player.get("aiResponse3") === undefined ||
      condition.condition === 0
    );
  };

  useEffect(() => {
    setAiResponse1(player.get("aiResponse1"));
    setAiResponse2(player.get("aiResponse2"));
    setAiResponse3(player.get("aiResponse3"));
  }, [player]);

  return (
    <>
      {isLoading() && <div className="loader"></div>}
      {!isLoading() && (
        <div>
          {" "}
          <p>
            <strong>
              To assist you, we provide some guidance to help you think about
              future research directions (based on your abstract using a
              generative AI tool).
            </strong>{" "}
          </p>
          <div className="prompt-response">
            <p>
              AI Suggestion{condition === 3 && " 1"}: {aiResponse1}
            </p>
          </div>
          {condition === 3 && (
            <>
              <div className="prompt-response">
                <p>AI Suggestion 2: {aiResponse2}</p>
              </div>
              <div className="prompt-response">
                <p>AI Suggestion 3: {aiResponse3}</p>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
