import React from "react";
import { Button } from "../components/Button";

export function Introduction({ next }) {
  return (
    <div className="mt-3 sm:mt-5 p-20">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Instructions
      </h3>
      <div className="mt-2 mb-6">
        <p className="text-sm text-gray-500">
          In this experiment, we would like to ask you to pay close attention to
          the scenario that will be presented to you. You need to complete the
          assigned task(s) as if you were carrying out your work duties.
        </p>
        <div className="flex-c">
          <Button className="main-btn" handleClick={next}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
